<template lang="pug">
  .athan
    v-container
      div Athan

</template>

<script>

export default {
  name: 'Athan',
  data: () => ({ 
  }),
  mounted () {
  },
  methods: {
  }
}
</script>
